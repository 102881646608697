import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createState } from './state/createState';
import { initialStateFactory } from './state/initialStateFactory';
import { createDailyAgendaActions } from './actions/actions';
import { createDailyAgendaViewModel } from './viewModel/viewModel';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig: { setProps },
}) => {
  let unsubscribeToState: () => void;

  return {
    async pageReady() {
      const initialState = initialStateFactory();
      const { getState, setState, subscribe } = createState(initialState);
      const actions = createDailyAgendaActions({ getState, setState });
      const viewModel = createDailyAgendaViewModel({
        state: getState(),
        flowAPI,
      });

      setProps({ viewModel, actions });

      unsubscribeToState = subscribe((state) => {
        setProps({ viewModel: createDailyAgendaViewModel({ state, flowAPI }) });
      });
    },

    onBeforeUnLoad: () => {
      unsubscribeToState?.();
    },
  };
};

export default createController;
