import { DailyAgendaViewModelFactory } from '../viewModel';

export type AgendaItemViewModel = {
  serviceName?: string;
  serviceImage?: { src: string; alt?: string };
  image?: any;
  startTime?: string;
  duration?: string;
  staffName?: string;
  staffImage?: string;
  location?: string;
  spots?: string;
  price?: string;
  buttonText?: string;
  disabled?: boolean;
};

export type DaySectionViewModel = {
  date: string;
  day: string;
  agendaItems: AgendaItemViewModel[];
};

export type BodyViewModel = {
  days: DaySectionViewModel[];
};

export const createBodyViewModel: DailyAgendaViewModelFactory<
  BodyViewModel
> = ({ state, flowAPI }) => {
  return {
    days: [],
  };
};
