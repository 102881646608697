import settingsParams from '../../settingsParams';
import { ViewModelFactoryArgs } from '../../state/types';
import { DailyAgendaViewModelFactory } from '../viewModel';

export type HeaderViewModel = {
  title: string;
  titleTagName: string;
};

export const createHeaderViewModel: DailyAgendaViewModelFactory<
  HeaderViewModel
> = (viewModelFactoryArgs: ViewModelFactoryArgs) => {
  const settings = viewModelFactoryArgs.flowAPI.settings;

  return {
    title: settings.get(settingsParams.titleText) || 'Class Schedule',
    titleTagName: settings.get(settingsParams.titleTagName) || 'h2',
  };
};
