import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import applicationJson from '../../../.application.json';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  titleTagName: SettingsParamType.String;
  titleText: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  titleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.titleText'),
  },
  titleTagName: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h2',
  },
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.greetingsPretext')} ${
        applicationJson.appName
      }`,
  },
});
