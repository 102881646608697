import { createStore } from 'zustand/vanilla';
import { DailyAgendaState } from './types';

export type StateUpdater = (
  prevState: DailyAgendaState,
) => Partial<DailyAgendaState>;

export type SetState = (
  state: Partial<DailyAgendaState> | StateUpdater,
) => void;

export type GetState = () => DailyAgendaState;

export type Subscribe = (
  listener: (state: DailyAgendaState) => void,
) => () => void;

export const createState = (
  initialState: DailyAgendaState,
): { setState: SetState; getState: GetState; subscribe: Subscribe } => {
  const state = createStore<DailyAgendaState>(() => initialState);

  return state;
};
