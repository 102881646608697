import { GetState, SetState } from '../state/createState';

export type DailyAgendaActions = {
  incrementCounter: () => void;
  decrementCounter: () => void;
  setName: (name: string) => void;
};

export type DailyAgendaActionFactory<ActionFN extends Function> = (args: {
  setState: SetState;
  getState: GetState;
}) => ActionFN;

const createIncrementCounter: DailyAgendaActionFactory<
  DailyAgendaActions['incrementCounter']
> =
  ({ setState }) =>
  () => {
    setState((prevState) => ({
      counter: prevState.counter + 1,
    }));
  };

const createDecrementCounter: DailyAgendaActionFactory<
  DailyAgendaActions['decrementCounter']
> =
  ({ setState }) =>
  () => {
    setState((prevState) => ({
      counter: prevState.counter - 1,
    }));
  };

const createSetName: DailyAgendaActionFactory<DailyAgendaActions['setName']> =
  ({ setState }) =>
  (name) => {
    setState(() => ({
      name,
    }));
  };

export const createDailyAgendaActions = (args: {
  setState: SetState;
  getState: GetState;
}): DailyAgendaActions => ({
  incrementCounter: createIncrementCounter(args),
  decrementCounter: createDecrementCounter(args),
  setName: createSetName(args),
});
